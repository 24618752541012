.homepage {
  width: 70%;
  margin-left: 3%;
  color: rgb(48, 14, 48);

  @media (max-width: 500px) {
    width: 90%;
    margin-left: 5%;
  }

  img {
    width: 120px;
    box-shadow: 3px 3px 3px gray;
    border-radius: 50%;
    margin-left: 90%;
    margin-top: 40px;

    @media (max-width: 500px) {
      // width: 25%;
      // margin-left: 37.5%;
      // margin-top: 20px;
      display: none;
    }
  }

  .dash {
    display: none;

    @media (max-width: 500px) {
      display: block;
      background: rgb(48, 14, 48);
      height: 5px;
      width: 20%;
      margin-left: 40%;
      margin-top: 20px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
  }

  h1 {
    font-size: 4rem;
    margin-bottom: 20px;

    @media (max-width: 500px) {
      text-align: center;
    }
  }

  p {
    font-size: 2rem;
    margin-bottom: 15px;

    @media (max-width: 500px) {
      text-align: justify;
    }
  }

  .signoff {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .button-container {
    margin-top: 40px;

    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    .browser {
      @media (max-width: 500px) {
        display: none;
      }
    }

    a {
      text-decoration: none;
      border: none;
      background: #ffe6e6;
      color: rgb(48, 14, 48);
      margin-right: 20px;
      font-size: 2rem;
      padding: 10px 30px 10px 30px;
      border-radius: 5px;

      &:hover {
        background: rgb(48, 14, 48);
        color: #ffe6e6;
      }

      @media (max-width: 500px) {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  .sign-off {
    display: flex;
    // width: auto;
    justify-content: flex-start;
    @media (max-width: 500px) {
      justify-content: space-between;
    }

    .sign-off-text {
      white-space: nowrap;
    }
  }

  .linkedin {
    border: none;
    border-radius: 0;
    height: 25px;
    width: auto;
    box-shadow: none;
    margin-left: -12px;
    margin-top: -3px;
    cursor: pointer;

    @media (max-width: 500px) {
      display: block;
      height: 50px;
      margin-top: -27px;
    }
  }
}
