header {
  display: none;

  @media (max-width: 500px) {
    display: block;
    height: 105px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(48, 14, 48);
    background: #c968ca;
    margin-bottom: 10px;
    padding-top: 20px;
    box-sizing: border-box;
    padding-bottom: 20px;
    // padding: 20px 0 20px 0;
  }

  h1 {
    @media (max-width: 500px) {
      font-size: 2.5rem;
      margin-left: 20px;
    }
  }

  img {
    @media (max-width: 500px) {
      width: 60px;
      margin-right: 20px;
      // margin-left: 20px;
    }
  }
}
