.sidebar {
  width: 20%;
  background: #c968ca;
  padding-bottom: 20px;
  margin-bottom: -100%;
  overflow: hidden;
  border-right: 5px solid rgb(238, 196, 196);

  @media (max-width: 500px) {
    display: none;
  }

  img {
    width: 86%;
    margin-left: 7%;
    margin-top: 20px;
    border-radius: 10px;
  }

  p {
    margin-left: 10%;
    font-size: 2.3rem;
    margin-top: 20px;
    color: rgb(48, 14, 48);
  }
}
